import React from 'react';
import WorkInProgressPage from '../../Components/Shared/WorkInProgressPage';

const Error404 = () => {
  return (
    <>
      <WorkInProgressPage />
    </>
  );
};

export default Error404;
